
@media screen and (max-width: 451px) {
    .white-paragraph{
        font-size: 16px!important;
    }
    .frame-5{
        flex-direction: column;
        margin-top: 10vh;
    }
    .logo{
        width: 40vw;
    }
    .text{
        font-size: 12px;
    }
    .div-5{
        flex-direction: column-reverse;
    }
    .heading{
        width: auto!important;
        flex-direction: column;
    }
    .img-4{
        width: 90vw!important;
    }
    .p{
        font-size: 16px!important;
        width: auto!important;
    }
    .text-wrapper-69{
        width: auto!important;
    }
    .heading-70{
        left: 0vw!important;
        width: auto!important;
    }
    .div-50{
        flex-direction: column;
    }
    .sub-header{
        font-size: 22px;
    }
    .frame-39{
        flex-direction: column;
    }
    .hiw{
        width: 95vw!important;
        height: 30vh!important;
    }
    .frame-40{
        flex-direction: column;
    }
    .state{
        border-top-right-radius: 16px;
    }
    .state-4{
        border-bottom-left-radius: 16px;
    }
    .frame-39{
        gap: 8px!important;
    }
    .frame-9{
        flex-direction: column;
    }
    .logo-2{
        width: 75vw;
        padding: 12px!important;
    }
    /* .frame-11{
        height: 40vh;
    } */
    .text-wrapper-2{
        font-size: 24px;
    }
    .text-wrapper-5{
        font-size: 12px;
    }
    .logo-3{
        width: 50px!important;
    }
    .text-wrapper-6{
        font-size: 10px!important;
    }
    .frame-18{
        margin-bottom: 4vh!important;
    }
    .lose-the-padding{
        padding: 0px!important;
    }
    .text-wrapper-8{
        font-size: 20px!important;
    }
    .frame-37{
        height: 30vh!important;
    }
    .select-subscription-section{
        flex-direction: column;
    }
    .prices{
        padding: 16px!important;
        font-size: 16px!important;
    }
    .user-info-section{
        flex-direction: column;
    }
    .user-info .div-3{
        width: 100%!important;
        padding: 100px 0px 0px!important;
        gap: 0px!important;
    }
    .user-info .img-2{
        width: auto!important;
    }
    .user-info .div-relative-2{
        padding: 0!important;
    }
    .user-info .div-relative{
        padding: 24px 0px!important;
    }
    .approved-section{
        flex-direction: column;
    }
    .approved-div-3{
        width: 100%!important;
        padding: 140px 14px 0px!important;
    }
    .approved-text-wrapper-3{
        font-size: 28px;
    }
    .approved-form{
        padding: 14px!important;
        gap: 8px!important;
    }
    .approved-frame-5{
        padding: 14px!important;
    }
    .approved-text-wrapper-5{
        font-size: 12px!important;
        width: 120px!important;
    }
    .approved-text-wrapper-7{
        font-size: 28px!important;
    }
    .approved-frame-9{
        padding: 8px!important;
    }
    .approved-frame-10{
        padding: 8px!important;
    }
    .approved-frame-11{
        padding: 8px!important;
    }
    .approved-img-2{
        width: auto!important;
    }
    .index .jybe-virtual-card-wrapper{
        flex-direction: column;
        gap: 14px!important;
        width: 320px!important;
    }
    .card-div{
        margin-top: 12vh!important;
    }
    .index .jybe-virtual-card{
        height: 200px!important;
        width: 300px!important;
    }
    .card-text-wrapper{
        font-size: 20px!important;
    }
    .card-img{
        top: 24%!important;
        font-size: 24px!important;
    }
    .expiry{
        top: 41%!important;
    left: 47%!important;
    }
    .name{
        top: 41%!important;
    left: 10%!important;
    }
    .cvv{
        top: 72.5%!important;
        left: 84%!important;
        font-size: 12px!important;
        width: 25px!important;
        height: 15px!important;
    }
    .div-grid{
        justify-content: space-between!important;
        flex-direction: column;
        gap: 20px;
        align-items: start!important;
        width: 80vw!important;
    }
    .div-7{
        flex-direction: column;
        align-items: flex-start;
        gap: 2.5vh;
    }
    .select-subscription-img{
        width: 100vw;
    }
    .plan-saving > div > div > p{
        gap: 9px!important;
    }
    .jybe-container{
        width: 100vw;
      }
      
      .jybe-iframe{
        width: 100vw;
      }
      .group-2{
        display: none;
      }
      .sixppl{
        display: none;
      }
      .frame-5{
        width: auto!important;
      }
      .frame-6{
        width: auto!important;
      }
      .section{
        padding: 0 5vw!important;
      }
      .section-2{
        flex-direction: column;
      }
      .we-help-subscription{
        font-size: 36px!important;
        text-align: center!important;
        width: auto!important;
      }
      .section-3{
        flex-direction: column-reverse;
      }
      .phone{
        height: 60vh!important;
        width: 100vw!important;
      }
      .text-wrapper-28{
        font-size: 30px!important;
      }
      .text-wrapper-29{
        font-size: 14px!important;
      }
      .text-wrapper-30{
        font-size: 18px!important;
      }
      .text-wrapper-31{
        font-size: 10px!important;
      }
      .frame-21{
        gap: 10px;
        padding: 5vw!important;
      }
      .div-box{
        padding: 8px!important;
      }
      .form{
        width: 90vw!important;
      }
      /* .frame-23{
        flex-direction: column;
      } */
      .text-wrapper-32{
        font-size: 18px!important;
      }
      .frame-22{
        padding: 2vh 0!important;
      }
      .by-checking-ths-box{
        font-size: 10px!important;
      }
      .section-4{
        padding: 2vh 5vw!important;
      }
      .white-header-1{
        font-size: 40px!important;
      }
      .navbar{
        padding: 0!important;
      }
      .section-heading{
        font-size: 12px!important;
      }
      .section-heading-2{
        font-size: 8px!important;
      }
      .group-20{
        display: none;
      }
      .logo{
        height: 32.872px !important;
        width: 138px !important;
      }
      .text-7{
        font-size: 16px!important;
      }
      .pointer,
      .link,
      .text-wrapper-23{
        font-size: 10px;
      }
}

/* @media screen and (min-width: 390px) and (max-width: 451px) {
    .text-wrapper-16{
        font-size: 8px!important;
    }
} */

@media screen and (max-width: 376px) {
    .div-grid{
        width: 19vw!important;
    }
    .text-wrapper-16{
        font-size: 8px!important;
    }
}

@media screen and (max-width: 331px) {
    .div-grid{
        width: 10vw!important;
    }
    .text{
        font-size: 12px!important;
    }
    .plan-saving > div {
        padding: 0px 10px!important;
        gap: 0px!important;
    }
    .plan-saving > div > div > p {
        gap: 6.5px!important;
    }
    .pc-payment-card{
        width: 100vw!important;
        padding: 0px!important;
        border: 0px!important;
        border-radius: 0px!important;
    }
    .big-frame{
        padding: 0px!important;
        border-radius: 0px!important;
    }
}