@media screen and (min-width: 600px) {
    .img-3{
        max-width: 50vw;
    }
    .frame-36{
        padding: 2vw;
        border-radius: 24px;
    }
    .section{
        padding: 2vh 5vw;
    }
    .state{
        border-bottom-left-radius: 16px;
    }
    .state-4{
        border-top-right-radius: 16px;
    }
    .carousel-card{
        gap: 0.625rem;
    }
    .heading-2{
        padding: 0px 120px;
    }
    .carousel-card{
        padding: 1rem!important;
    }
    .frame-9{
        height: 60vh;
    }
    .brand-list{
        width: 20vw;
    }
    .logo-2{
        width: 15vw;
    }
    .frame-13{
        padding: 16px 0px;
    }
    .text-wrapper-5{
        font-size: 2vw;
    }
    .frame-20{
        width: 20vw;
    }
    .select-subscription-div-3{
        width: 60vw;
    }
    /* .select-subscription-form{
        margin-bottom: -171px;
    } */
    .select-subscription-img{
        width: 40vw;
        height: 80%;
    }
    .select-subscription-section{
        height: 100vh;
    }
    .select-subscription-text-wrapper-6{
        font-size: 16px;
    }
    .select-subscription-frame-6::-webkit-scrollbar {
        width: 11px!important; /* Set the width of the scrollbar */
      }
      .card-div{
        margin-top: 20vh;
        height: 80vh;
      }
}