.jybe-business-page {
  background-color: #ffffff;
}

.text-wrapper-3{
  font-size: 1.5vw;
}

.filled{
  background: #1a4ab1!important;
  border-radius: 10px;
}

.jybe-business-page .section {
  display: flex;
  flex-direction: column;
  height: 100vh; /* Converted from 1080px */
  justify-content: center;
  padding: 0 12.5vw; /* Converted from 240px */
  width: 100vw; /* Converted from 1920px */
}

.jybe-business-page .group-2 {
  box-shadow: 0px 0.92vw 0.92vw rgba(0, 0, 0, 0.2); /* Converted from 0px 16.52px 16.52px #00000033 */
  height: 39.95vh; /* Converted from 431px */
  left: 78.44vw; /* Converted from 1506px */
  position: absolute;
  top: 21.02vh; /* Converted from 227px */
  width: 14.95vw; /* Converted from 287px */
  z-index: 3;
}
.jybe-business-page .overlap-group-wrapper {
  height: 26.39vh; /* Converted from 285px */
  left: 0;
  position: absolute;
  top: 13.52vh; /* Converted from 146px */
  width: 15.15vw; /* Converted from 291px */
}

.jybe-business-page .overlap-group-2 {
  background: linear-gradient(180deg, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0.68) 100%);
  border-radius: 0.31vw; /* Converted from 5.98px */
  height: 26.39vh; /* Converted from 285px */
  
  width: 14.95vw; /* Converted from 287px */
}

.jybe-business-page .group-3 {
  height: 18.70vh; /* Converted from 202px */
  left: 1.04vw; /* Converted from 20px */
  position: absolute;
  top: 6.30vh; /* Converted from 68px */
  width: 12.82vw; /* Converted from 247px */
}

.jybe-business-page .group-4 {
  height: 8.97vh; /* Converted from 97px */
  left: 0;
  position: absolute;
  top: 9.72vh; /* Converted from 105px */
  width: 0.67vw; /* Converted from 13px */
}

.jybe-business-page .rectangle-2 {
  background-color: #1a4ab1;
  height: 5.99vh; /* Converted from 65px */
  left: 0;
  position: absolute;
  top: 2.87vh; /* Converted from 31px */
  width: 0.67vw; /* Converted from 13px */
}

.jybe-business-page .rectangle-3 {
  background-color: #1a4ab133;
  height: 2.50vh; /* Converted from 27px */
  left: 0;
  position: absolute;
  top: 0;
  width: 0.67vw; /* Converted from 13px */
}

.jybe-business-page .new-group-5 {
  height: 11.38vh; /* Converted from 123px */
  left: 2.03vw; /* Converted from 39px */
  position: absolute;
  top: 7.31vh; /* Converted from 79px */
  width: 0.67vw; /* Converted from 13px */
}

.jybe-business-page .rectangle-4 {
  background-color: #1a4ab1;
  height: 7.57vh; /* Converted from 83px */
  left: 0;
  position: absolute;
  top: 3.67vh; /* Converted from 40px */
  width: 0.67vw; /* Converted from 13px */
}

.jybe-business-page .rectangle-5 {
  background-color: #1a4ab133;
  height: 3.17vh; /* Converted from 34px */
  left: 0;
  position: absolute;
  top: 0;
  width: 0.67vw; /* Converted from 13px */
}

.jybe-business-page .group-6 {
  height: 12.04vh; /* Converted from 130px */
  left: 4.06vw; /* Converted from 78px */
  position: absolute;
  top: 6.67vh; /* Converted from 72px */
  width: 0.67vw; /* Converted from 13px */
}

.jybe-business-page .rectangle-6 {
  background-color: #1a4ab1;
  height: 9.18vh; /* Converted from 88px */
  left: 0;
  position: absolute;
  top: 3.86vh; /* Converted from 42px */
  width: 0.67vw; /* Converted from 13px */
}

.jybe-business-page .rectangle-7 {
  background-color: #1a4ab133;
  height: 3.36vh; /* Converted from 36px */
  left: 0;
  position: absolute;
  top: 0;
  width: 0.67vw; /* Converted from 13px */
}

.jybe-business-page .group-7 {
  height: 14.91vh; /* Converted from 161px */
  left: 6.13vw; /* Converted from 117px */
  position: absolute;
  top: 3.80vh; /* Converted from 41px */
  width: 0.67vw; /* Converted from 13px */
}

.jybe-business-page .rectangle-8 {
  background-color: #1a4ab1;
  height: 9.97vh; /* Converted from 109px */
  left: 0;
  position: absolute;
  top: 4.80vh; /* Converted from 52px */
  width: 0.67vw; /* Converted from 13px */
}

.jybe-business-page .rectangle-9 {
  background-color: #1a4ab133;
  height: 4.14vh; /* Converted from 45px */
  left: 0;
  position: absolute;
  top: 0;
  width: 0.67vw; /* Converted from 13px */
}

.jybe-business-page .group-8 {
  height: 13.26vh; /* Converted from 143px */
  left: 8.13vw; /* Converted from 156px */
  position: absolute;
  top: 5.93vh; /* Converted from 58px */
  width: 0.67vw; /* Converted from 13px */
}

.jybe-business-page .rectangle-10 {
  background-color: #1a4ab1;
  height: 8.97vh; /* Converted from 97px */
  left: 0;
  position: absolute;
  top: 4.44vh; /* Converted from 46px */
  width: 0.67vw; /* Converted from 13px */
}

.jybe-business-page .rectangle-11 {
  background-color: #1a4ab133;
  height: 3.70vh; /* Converted from 40px */
  left: 0;
  position: absolute;
  top: 0;
  width: 0.67vw; /* Converted from 13px */
}

.jybe-business-page .group-9 {
  height: 16.20vh; /* Converted from 175px */
  left: 10.16vw; /* Converted from 195px */
  position: absolute;
  top: 2.50vh; /* Converted from 27px */
  width: 0.67vw; /* Converted from 13px */
}

.jybe-business-page .rectangle-12 {
  background-color: #1a4ab1;
  height: 10.87vh; /* Converted from 118px */
  left: 0;
  position: absolute;
  top: 5.56vh; /* Converted from 57px */
  width: 0.67vw; /* Converted from 13px */
}

.jybe-business-page .rectangle-13 {
  background-color: #1a4ab133;
  height: 4.51vh; /* Converted from 49px */
  left: 0;
  position: absolute;
  top: 0;
  width: 0.67vw; /* Converted from 13px */
}

.jybe-business-page .group-10 {
  height: 18.70vh; /* Converted from 202px */
  left: 12.18vw; /* Converted from 234px */
  position: absolute;
  top: 0;
  width: 0.67vw; /* Converted from 13px */
}

.jybe-business-page .rectangle-14 {
  background-color: #1a4ab1;
  height: 9.99vh; /* Converted from 136px */
  left: 0;
  position: absolute;
  top: 6.00vh; /* Converted from 65px */
  width: 0.67vw; /* Converted from 13px */
}

.jybe-business-page .rectangle-15 {
  background-color: #1a4ab133;
  height: 4.89vh; /* Converted from 56px */
  left: 0;
  position: absolute;
  top: 0;
  width: 0.67vw; /* Converted from 13px */
}

.jybe-business-page .text-wrapper-8 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 0.39vw; /* Converted from 7.5px */
  font-weight: 700;
  left: 12.03vw; /* Converted from 230px */
  
  line-height: normal;
  position: absolute;
  top: 1.20vh; /* Converted from 13px */
}

.jybe-business-page .text-wrapper-9 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 1.24vw; /* Converted from 13.4px */
  font-weight: 700;
  left: 0.63vw; /* Converted from 12px */
  
  line-height: normal;
  position: absolute;
  top: 1.20vh; /* Converted from 13px */
}

.jybe-business-page .overlap-wrapper {
  height: 11.48vh; /* Converted from 124px */
  left: 0;
  position: absolute;
  top: 0;
  width: 15.04vw; /* Converted from 289px */
}

.jybe-business-page .overlap {
  background-color: #29292f;
  border-radius: 0.31vw; /* Converted from 5.98px */
  height: 11.48vh; /* Converted from 124px */
  
  width: 14.95vw; /* Converted from 287px */
}

.jybe-business-page .text-wrapper-10 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 1.24vw; /* Converted from 13.4px */
  font-weight: 700;
  left: 0.63vw; /* Converted from 12px */
  
  line-height: normal;
  position: absolute;
  top: 1.20vh; /* Converted from 13px */
}

.jybe-business-page .group-11 {
  height: 1.85vh; /* Converted from 20px */
  left: 0.63vw; /* Converted from 12px */
  position: absolute;
  top: 5.09vh; /* Converted from 55px */
  width: 13.59vw; /* Converted from 263px */
}

.jybe-business-page .text-wrapper-11 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 1vw; /* Converted from 13.4px */
  font-weight: 700;
  left: 0;
  
  line-height: normal;
  position: absolute;
  top: 0;
}

.jybe-business-page .text-wrapper-12 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 1vw; /* Converted from 13.4px */
  font-weight: 700;
  left: 10.35vw; /* Converted from 217px */
  
  line-height: normal;
  position: absolute;
  text-align: right;
  top: 0;
}

.jybe-business-page .group-12 {
  height: 2.31vh; /* Converted from 20px */
  left: 0.63vw; /* Converted from 12px */
  position: absolute;
  top: 8.24vh; /* Converted from 89px */
  width: 13.59vw; /* Converted from 263px */
}

.jybe-business-page .text-wrapper-13 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 1vw; /* Converted from 13.4px */
  font-weight: 700;
  left: 10.6vw; /* Converted from 222px */
  
  line-height: normal;
  position: absolute;
  text-align: right;
  top: 0;
}

.jybe-business-page .frame-5 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 1.67vh; /* Converted from 32px */
  justify-content: center;
  
  width: 50vw; /* Converted from 712px */
  z-index: 2;
}

.jybe-business-page .columns {
  display: flex;
  flex-direction: column;
  gap: 0.5vh;
  width: 100%;
}

.jybe-business-page .white-header-1 {
  align-self: stretch;
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 5vw; /* Converted from 64px */
  font-weight: 700;
  
  line-height: normal;
  
}

.jybe-business-page .white-paragraph {
  align-self: stretch;
  color: white;
  font-family: "Poppins", Helvetica;
  font-size: 2.5vw; /* Converted from 24px */
  font-weight: 700;
  line-height: normal;
}

.jybe-business-page .demo-button {
  background-color: #ffffff;
  border-radius: 12px; /* Converted from 12px */
  padding: 12px 24px; /* Converted from 12px 24px */
  border: 0px;
}

.jybe-business-page .text-6 {
  color: #1a4ab1;
  font-family: "Poppins", Helvetica;
  font-size: 16px; /* Converted from 16px */
  font-weight: 700;
  
  line-height: normal;
  margin-top: -0.09vh; /* Converted from -1px */
  
  width: fit-content;
}

.jybe-business-page .image{
  height: 100vh; /* Converted from 1080px */
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100vw; /* Converted from 1920px */
  z-index: 1;
}

.jybe-business-page .section-2 {
  align-items: center;
  background-color: #f4f6fa;
  display: flex;
  height: 64.81vh; /* Converted from 700px */
  justify-content: center;
  width: 100vw; /* Converted from 1920px */
}

.phone{
  height: 100vh;
  width: 50vw;
}

.jybe-business-page .frame-6 {
  padding: 3.13vh 4.17vw; /* Converted from 60px 80px */
}

.jybe-business-page .we-help-subscription {
  font-size: 3.33vw; /* Converted from 64px */
  font-weight: 700;
  line-height: normal;
  margin-right: -1.82vw; /* Converted from -35px */
  width: 31.15vw; /* Converted from 595px */
}

.jybe-business-page .frame-7{
    align-self: stretch;
    display: flex;
    flex: 1 1;
}

.jybe-business-page .frame-8{
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 3.13vh 1.56vw; /* Converted from 60px 30px */
  background: rgba(42, 42, 48, 0.80);
  justify-content: space-between;
}
.jybe-business-page .frame-10{
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 3.13vh 1.56vw; /* Converted from 60px 30px */
  background: #34343D;
  justify-content: space-between;
}
.jybe-business-page .frame-11 {
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 3.13vh 1.56vw; /* Converted from 60px 30px */
  background: #2A2A30;
  justify-content: space-between;
}

.jybe-business-page .icon-instance-node {
  height: 6.48vh !important; /* Converted from 70px */
  position: relative !important;
  width: 6.48vh !important; /* Converted from 70px */
}

.jybe-business-page .frame-9 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.jybe-business-page .text-wrapper-16 {
  align-self: stretch;
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 12px; /* Converted from 20px */
  font-weight: 700;
  
  line-height: normal;
  
}

.jybe-business-page .section-3 {
  align-items: flex-start;
  background-color: #ffffff;
  display: flex;
  flex: 0 0 auto;
  
  width: 100vw; /* Converted from 1920px */
}

.jybe-business-page .img-3 {
  background: linear-gradient(180deg, rgba(42, 41, 234, 0.58) 0%, rgba(23.69, 23.13, 132, 0.58) 100%);
  height: 17.04vh; /* Converted from 1180px */
  overflow: hidden;
  
  width: 50vw; /* Converted from 960px */
  z-index: 1;
}

.jybe-business-page .overlap-2 {
  height: 12.71vh; /* Converted from 1374px */
  left: -22.82vw; /* Converted from -439px */
  
  top: -11.48vh; /* Converted from -124px */
  width: 79.27vw; /* Converted from 1522px */
}

.jybe-business-page .ellipse {
  background-color: #efc327;
  border-radius: 17.38vw; /* Converted from 397.14px */
  filter: blur(9.26vw); /* Converted from 200px */
  height: 73.52vh; /* Converted from 794px */
  left: 0;
  opacity: 0.25;
  position: absolute;
  top: 38.80vh; /* Converted from 419px */
  width: 41.25vw; /* Converted from 794px */
}


.jybe-business-page .pyramid {
  height: 20.84vh;
  left: 23.28vw;
  opacity: 0.25;
  position: absolute;
  top: 84.72vh;
  transform: rotate(70deg);
  width: 20.84vw;
}

.jybe-business-page .overlap-group-3 {
  height: 30.62vh;
  left: -5.83vw;
  
  top: -11.85vh;
  width: 29.74vw;
}

.jybe-business-page .pyramid-2 {
  height: 23.13vh;
  left: 2.86vw;
  object-fit: cover;
  position: absolute;
  top: 6.67vh;
  transform: rotate(-70deg);
  width: 24.27vw;
}

.jybe-business-page .mask-group {
  height: 23.13vh;
  left: 2.86vw;
  position: absolute;
  top: 6.67vh;
  transform: rotate(-70deg);
  width: 24.27vw;
}

.jybe-business-page .ellipse-2 {
  background-color: #ff4073;
  border-radius: 23.35vw;
  filter: blur(10.42vw);
  height: 29.07vh;
  left: 52.81vw;
  opacity: 0.5;
  position: absolute;
  top: 0;
  width: 29.07vw;
}

.jybe-business-page .pyramid-3 {
  height: 20.36vh;
  left: 52.92vw;
  opacity: 0.25;
  position: absolute;
  top: 12.5vh;
  transform: rotate(-72.54deg);
  width: 20.36vw;
}

.jybe-business-page .overlap-3 {
  height: 28.39vh;
  left: -5.83vw;
  
  top: -8.52vh;
  width: 28.2vw;
}

.jybe-business-page .pyramid-4 {
  height: 23.47vh;
  left: 3.39vw;
  object-fit: cover;
  position: absolute;
  top: 3.85vh;
  transform: rotate(72.54deg);
  width: 22.42vw;
}

.jybe-business-page .mask-group-2 {
  height: 23.47vh;
  left: 3.39vw;
  position: absolute;
  top: 3.85vh;
  transform: rotate(72.54deg);
  width: 22.42vw;
}

.jybe-business-page .group-13 {
  height: 45.96vh;
  left: 34.9vw;
  position: absolute;
  top: 26.22vh;
  width: 27.92vw;
}

.jybe-business-page .overlap-4 {
  height: 45.96vh;
  
  width: 27.92vw;
}

.jybe-business-page .body {
  height: 45.96vh;
  left: 2.39vw;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 22.6vw;
}

.jybe-business-page .group-14 {
  height: 42.92vh;
  left: 2.92vw;
  position: absolute;
  top: 1.39vh;
  width: 27.92vw;
}

.jybe-business-page .overlap-5 {
  height: 44.42vh;
  
  width: 27.92vw;
}

.jybe-business-page .rectangle-16 {
  height: 41.34vh;
  left: 3.6vw;
  position: absolute;
  top: 2.22vh;
  width: 20.42vw;
}

.jybe-business-page .status-bar-instance {
  background-color: unset !important;
  border-radius: 7.5vw !important;
  height: 3.11vh !important;
  left: 3.85vw !important;
  overflow: hidden !important;
  padding: 1.52vh 1.78vw 0.51vh !important;
  position: absolute !important;
  top: 0 !important;
  width: 19.84vw !important;
}

.jybe-business-page .design-component-instance-node {
  font-family: "Plus Jakarta Sans", Helvetica !important;
  font-size: 0.73vw !important;
  font-style: unset !important;
  font-weight: 700 !important;
  line-height: normal !important;
}

.jybe-business-page .status-bar-2 {
  border-radius: 14.69vw !important;
  height: 1.86vh !important;
  width: 6.1vw !important;
}

.jybe-business-page .status-bar-3 {
  gap: 1.45vw !important;
}

.jybe-business-page .status-bar-4 {
  height: 0.8vh !important;
  width: 4.25vw !important;
}

.jybe-business-page .status-bar-5 {
  height: 0.83vh !important;
}

.jybe-business-page .status-bar-6 {
  height: 0.83vh !important;
  left: 0.31vw !important;
}

.jybe-business-page .status-bar-7 {
  height: 0.83vh !important;
  left: 0.63vw !important;
}

.jybe-business-page .status-bar-8 {
  height: 0.83vh !important;
  left: 0.94vw !important;
}

.jybe-business-page .status-bar-9 {
  height: 0.83vh !important;
  left: 1.25vw !important;
}

.jybe-business-page .status-bar-10 {
  height: 1.45vh !important;
  width: 1.78vw !important;
}

.jybe-business-page .status-bar-11 {
  height: 1.45vh !important;
  width: 2.41vw !important;
}

.jybe-business-page .status-bar-12 {
  background-image: url(https://c.animaapp.com/AP7s1SnC/img/shape-5.svg) !important;
  height: 0.94vh !important;
  width: 1.52vw !important;
}

.jybe-business-page .status-bar-13 {
  height: 0.77vh !important;
  width: 0.77vh !important;
}

.jybe-business-page .status-bar-14 {
  left: 1.02vw !important;
  top: 0.42vh !important;
}

.jybe-business-page .rectangle-17 {
  height: 40.56vh;
  left: 2.76vw;
  position: absolute;
  top: 2.88vh;
  width: 20.83vw;
}

.jybe-business-page .group-15 {
  height: 6.56vh;
  left: 3.6vw;
  position: absolute;
  top: 6.67vh;
  width: 19.81vw;
}

.jybe-business-page .group-16 {
  height: 6.02vh;
  left: 0.94vw;
  position: absolute;
  top: 0;
  width: 17.5vw;
}

.jybe-business-page .outline-essentional {
  height: 2.83vh !important;
  left: 0 !important;
  position: absolute !important;
  top: 1.17vh !important;
  width: 2.83vh !important;
}

.jybe-business-page .outline-shopping {
  height: 2.83vh !important;
  left: 18.23vw !important;
  position: absolute !important;
  top: 1.17vh !important;
  width: 2.83vh !important;
}

.jybe-business-page .outline-search {
  height: 2.83vh !important;
  left: 16.13vw !important;
  position: absolute !important;
  top: 1.17vh !important;
  width: 2.83vh !important;
}

.jybe-business-page .voritt-logo {
  height: 6.02vh;
  left: 5.47vw;
  position: absolute;
  top: 0;
  width: 8.19vw;
}

.jybe-business-page .vector {
  height: 2.5vh;
  left: 2.45vw;
  position: absolute;
  top: 1.35vh;
  width: 2.16vw;
}

.jybe-business-page .vector-2 {
  height: 1.18vh;
  left: 3.39vw;
  position: absolute;
  top: 2.04vh;
  width: 1.11vw;
}

.jybe-business-page .vector-3 {
  height: 1.18vh;
  left: 4.27vw;
  position: absolute;
  top: 2.04vh;
  width: 0.74vw;
}

.jybe-business-page .vector-4 {
  height: 1.65vh;
  left: 4.8vw;
  position: absolute;
  top: 1.1vh;
  width: 0.37vw;
}

.jybe-business-page .vector-5 {
  height: 1.41vh;
  left: 5.2vw;
  position: absolute;
  top: 1.47vh;
  width: 0.74vw;
}

.jybe-business-page .vector-6 {
  height: 1.41vh;
  left: 5.74vw;
  position: absolute;
  top: 1.47vh;
  width: 0.74vw;
}

.jybe-business-page .overlap-group-4 {
  height: 2.27vh;
  left: 0.23vw;
  position: absolute;
  top: 0.61vh;
  width: 3.3vw;
}

.jybe-business-page .vector-7 {
  height: 0.87vh;
  left: 0;
  position: absolute;
  top: 1.67vh;
  width: 3.3vw;
}

.jybe-business-page .vector-8 {
  height: 1.51vh;
  left: 0;
  position: absolute;
  top: 0.74vh;
  width: 3.3vw;
}

.jybe-business-page .vector-9 {
  height: 0.65vh;
  left: 0.31vw;
  position: absolute;
  top: 1.87vh;
  width: 3.19vw;
}

.jybe-business-page .vector-10 {
  height: 0.87vh;
  left: 0;
  position: absolute;
  top: 0.74vh;
  width: 3.3vw;
}

.jybe-business-page .group-17 {
  height: 0.65vh;
  left: 0.31vw;
  position: absolute;
  top: 0.96vh;
  width: 3.19vw;
}

.jybe-business-page .img-4 {
  height: 1.51vh;
  left: 0;
  position: absolute;
  top: 0;
  width: 3.3vw;
}

.jybe-business-page .vector-11 {
  height: 0.13vh;
  left: 0;
  position: absolute;
  top: 7.92vh;
  width: 25.95vw;
}

.jybe-business-page .frame-12 {
  align-items: center;
  background-color: #ffffff;
  border: 0.38px solid;
  border-color: var(--neutralsgray-100);
  border-radius: 3.8vw;
  display: flex;
  justify-content: space-between;
  left: 5.61vw;
  padding: 3.8vw;
  position: absolute;
  top: 24.04vh;
  width: 20.91vw;
}

.jybe-business-page .frame-13 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 1.46vw;
  
}

.jybe-business-page .toggle-radio {
  height: 1.66vh;
  
  width: 1.66vh;
}

.jybe-business-page .rectangle-18 {
  background-color: #ffffff;
  border: 0.38px solid;
  border-color: #c8d5e0;
  border-radius: 38.16%;
  height: 1.4vh;
  left: 0.15vw;
  
  top: 0.15vh;
  width: 1.4vh;
}

.jybe-business-page .frame-14 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  justify-content: center;
  
}

.jybe-business-page .voritt-logo-2 {
  height: 3.33vh;
  
  width: 10vh;
}

.jybe-business-page .vector-12 {
  height: 1.07vh;
  left: 2.72vw;
  position: absolute;
  top: 1.1vh;
  width: 0.94vw;
}

.jybe-business-page .vector-13 {
  height: 0.8vh;
  left: 3.78vw;
  position: absolute;
  top: 1.47vh;
  width: 0.8vw;
}

.jybe-business-page .vector-14 {
  height: 0.8vh;
  left: 5.08vw;
  position: absolute;
  top: 1.47vh;
  width: 0.53vw;
}

.jybe-business-page .vector-15 {
  height: 1.12vh;
  left: 5.72vw;
  position: absolute;
  top: 0.74vh;
  width: 0.27vw;
}

.jybe-business-page .vector-16 {
  height: 0.98vh;
  left: 6.36vw;
  position: absolute;
  top: 1.1vh;
  width: 0.8vw;
}

.jybe-business-page .vector-17 {
  height: 0.98vh;
  left: 7.08vw;
  position: absolute;
  top: 1.1vh;
  width: 0.8vw;
}

.jybe-business-page .overlap-group-5 {
  height: 1.81vh;
  left: 0.31vw;
  position: absolute;
  top: 0.48vh;
  width: 2.08vw;
}

.jybe-business-page .vector-18 {
  height: 0.65vh;
  left: 0;
  position: absolute;
  top: 1.1vh;
  width: 2.08vw;
}

.jybe-business-page .vector-19 {
  height: 1.12vh;
  left: 0;
  position: absolute;
  top: 0.53vh;
  width: 2.08vw;
}

.jybe-business-page .vector-20 {
  height: 0.48vh;
  left: 0.31vw;
  position: absolute;
  top: 1.24vh;
  width: 2.03vw;
}

.jybe-business-page .vector-21 {
  height: 0.65vh;
  left: 0;
  position: absolute;
  top: 0.59vh;
  width: 2.08vw;
}

.jybe-business-page .group-18 {
  height: 0.48vh;
  left: 0.31vw;
  position: absolute;
  top: 0.71vh;
  width: 2.03vw;
}

.jybe-business-page .img-5 {
  height: 1.07vh;
  left: 0;
  position: absolute;
  top: 0;
  width: 2.08vw;
}

.jybe-business-page .text-wrapper-17 {
  color: #606970;
  font-family: "Poppins", Helvetica;
  font-size: 1.77vw;
  font-weight: 700;
  
  line-height: normal;
  
  width: auto;
}

.jybe-business-page .frame-15 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 0.59vw;
  
}

.jybe-business-page .text-wrapper-18 {
  color: var(--neutralsgray-500);
  font-family: "Poppins", Helvetica;
  font-size: 2.46vw;
  font-weight: 700;
  
  line-height: normal;
  margin-top: -0.1vh;
  
  width: auto;
}

.jybe-business-page .text-wrapper-19 {
  color: #9ba6b0;
  font-family: "Poppins", Helvetica;
  font-size: 1.47vw;
  font-weight: 700;
  
  line-height: normal;
  
  width: auto;
}

.jybe-business-page .text-wrapper-20 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 1.77vw;
  font-weight: 700;
  left: 14.73vw;
  
  line-height: normal;
  position: absolute;
  top: 17.42vh;
}

.jybe-business-page .frame-16 {
  -webkit-backdrop-filter: blur(3.33vw) brightness(100%);
  align-items: center;
  backdrop-filter: blur(3.33vw) brightness(100%);
  background-color: #ffffff80;
  border: 0.05vw solid;
  border-color: var(--neutralsgray-100);
  border-radius: 1.47vw;
  box-shadow: 0px 2.96vw 2.96vw #00000033;
  display: flex;
  justify-content: space-between;
  left: 9.42vw;
  padding: 2.67vh 1.53vw;
  position: absolute;
  top: 48.6vh;
  width: 26.19vw;
}

.jybe-business-page .voritt-logo-3 {
  height: 6.15vh;
  
  width: 18.45vw;
}

.jybe-business-page .vector-22 {
  height: 1.87vh;
  left: 9.87vw;
  position: absolute;
  top: 6.15vh;
  width: 1.6vw;
}

.jybe-business-page .vector-23 {
  height: 1.47vh;
  left: 16.06vw;
  position: absolute;
  top: 7.19vh;
  width: 1.4vw;
}

.jybe-business-page .vector-24 {
  height: 1.47vh;
  left: 21.14vw;
  position: absolute;
  top: 7.19vh;
  width: 0.94vw;
}

.jybe-business-page .vector-25 {
  height: 2.04vh;
  left: 24.5vw;
  position: absolute;
  top: 3.89vh;
  width: 0.47vw;
}

.jybe-business-page .vector-26 {
  height: 1.81vh;
  left: 26.24vw;
  position: absolute;
  top: 5.2vh;
  width: 1.07vw;
}

.jybe-business-page .vector-27 {
  height: 1.81vh;
  left: 29.05vw;
  position: absolute;
  top: 5.2vh;
  width: 1.07vw;
}

.jybe-business-page .overlap-group-6 {
  height: 2.93vh;
  left: 0.62vw;
  position: absolute;
  top: 0.78vh;
  width: 3.33vw;
}

.jybe-business-page .vector-28 {
  height: 1.07vh;
  left: 0;
  position: absolute;
  top: 7.42vh;
  width: 3.33vw;
}

.jybe-business-page .vector-29 {
  height: 1.87vh;
  left: 0;
  position: absolute;
  top: 3.33vh;
  width: 3.33vw;
}

.jybe-business-page .vector-30 {
  height: 0.78vh;
  left: 0.63vw;
  position: absolute;
  top: 8.32vh;
  width: 3.14vw;
}

.jybe-business-page .vector-31 {
  height: 1.07vh;
  left: 0;
  position: absolute;
  top: 3.6vh;
  width: 3.33vw;
}

.jybe-business-page .group-19 {
  height: 0.78vh;
  left: 0.63vw;
  position: absolute;
  top: 4.08vh;
  width: 3.14vw;
}

.jybe-business-page .img-6 {
  height: 1.87vh;
  left: 0;
  position: absolute;
  top: 0;
  width: 3.33vw;
}

.jybe-business-page .text-wrapper-21 {
  color: #606970;
  font-family: "Poppins", Helvetica;
  font-size: 1.77vw;
  font-weight: 700;
  
  line-height: normal;
  
  width: auto;
}

.jybe-business-page .text-wrapper-22 {
  color: #9ba6b0;
  font-family: "Poppins", Helvetica;
  font-size: 1.53vw;
  font-weight: 700;
  
  line-height: normal;
  
  width: auto;
}

.jybe-business-page .frame-17 {
  -webkit-backdrop-filter: blur(3.33vw) brightness(100%);
  align-items: center;
  backdrop-filter: blur(3.33vw) brightness(100%);
  background-color: #ffffff80;
  border: 0.05vw solid;
  border-color: var(--neutralsgray-100);
  border-radius: 1.47vw;
  box-shadow: 0px 2.96vw 2.96vw #00000033;
  display: flex;
  justify-content: space-between;
  left: 0;
  padding: 2.67vh 1.53vw;
  position: absolute;
  top: 63.8vh;
  width: 29.8vw;
}

.jybe-business-page .rectangle-19 {
  background-color: #1a4ab1;
  border: 0.05vw solid;
  border-color: #0b2d77;
  border-radius: 9.67%;
  height: 1.47vh;
  left: 0.63vw;
  
  top: 0.63vh;
  width: 1.47vh;
}

.jybe-business-page .text-wrapper-23 {
  color: #606970;
  font-family: "Poppins", Helvetica;
  /* font-size: 1.33vw; */
  font-weight: 700;
  
  line-height: normal;
  
  width: auto;
}

.jybe-business-page .frame-18 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  
  width: 100%;
}

.jybe-business-page .text-wrapper-24 {
  color: #1a4ab1;
  font-family: "Poppins", Helvetica;
  font-size: 2.57vw;
  font-weight: 700;
  
  line-height: normal;
  margin-top: -0.1vh;
  
  width: auto;
}

.jybe-business-page .text-wrapper-25 {
  color: #1a4ab1;
  font-family: "Poppins", Helvetica;
  font-size: 1.53vw;
  font-weight: 700;
  
  line-height: normal;
  
  width: auto;
}
/* 
.jybe-business-page .logo {
  height: 1.47vh;
  
  width: 6.16vw;
} */

.jybe-business-page .frame-wrapper {
  align-items: flex-start;
  background-color: #1a4ab1;
  border-radius: 1.67vw;
  box-shadow: 0px 2.96vw 2.96vw #00000033;
  display: flex;
  flex-direction: column;
  gap: 3.33vh;
  justify-content: center;
  left: 1.53vw;
  padding: 2.67vh 4.57vw;
  position: absolute;
  top: 79.98vh;
  width: 29.4vw;
}

.jybe-business-page .frame-19 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  
  width: 100%;
}

.jybe-business-page .text-wrapper-26 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 3.57vw;
  font-weight: 700;
  
  line-height: normal;
  margin-top: -0.1vh;
  
  width: auto;
}

.jybe-business-page .bold-essentional-UI {
  height: 2.35vw !important;
  position: relative !important;
  width: 2.35vw !important;
}

.jybe-business-page .frame-20 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  gap: 1.47vw;
  
}

.jybe-business-page .text-wrapper-27 {
  color: #ffffff;
  font-family: "Poppins", Helvetica;
  font-size: 1.77vw;
  font-weight: 700;
  
  line-height: normal;
  margin-top: -0.1vh;
  
  width: auto;
}

.jybe-business-page .logo-2 {
  height: 3.33vh;
  
  width: 13.91vw;
}

.jybe-business-page .frame-21 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  padding: 0vh 5vw;
  
  z-index: 0;
  height: 100vh;
  justify-content: space-evenly;
}

.jybe-business-page .text-wrapper-28 {
  align-self: stretch;
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 3vw;
  font-weight: 700;
  
  line-height: normal;
  margin-top: -0.02vw;
  
}

.jybe-business-page .div-box {
  align-items: flex-start;
  align-self: stretch;
  background-color: #ffffff;
  border: 0.05vw solid;
  border-color: var(--neutralsgray-100);
  border-radius: 2.81vw;
  box-shadow: 0px 0.42vw 0.42vw #0e24241a;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 0.65vw;
  overflow: hidden;
  padding: 1.5vw;
  
  width: 100%;
}

.jybe-business-page .text-wrapper-30 {
  align-self: stretch;
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 1.5vw;
  font-weight: 700;
  
  line-height: normal;
  margin-top: -0.02vw;
  
}

.jybe-business-page .text-wrapper-31 {
  align-self: stretch;
  color: #00000099;
  font-family: "Poppins", Helvetica;
  font-size: 1vw;
  font-weight: 700;
  
  line-height: normal;
  
}

.jybe-business-page .frame-22 {
  align-items: center;
  display: flex;
  gap: 4.17vw;
  justify-content: center;
  padding: 5vh 5vw;
  
  width: 100vw;
}

.jybe-business-page .group-20 {
  height: 90vh;
  left: 52.19vw;
  position: absolute;
  top: -12vh;
  width: 47.92vw;
}

.jybe-business-page .form {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 1.33vw;
  
  width: 50vw;
}

.jybe-business-page .text-wrapper-32 {
  align-self: stretch;
  color: #00000099;
  font-family: "Poppins", Helvetica;
  font-size: 1.33vw;
  font-weight: 700;
  
  line-height: normal;
  
}

.jybe-business-page .frame-23 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 1.07vw;
  
  width: 100%;
}

.jybe-business-page .inputs-text-instance {
  flex: 1 !important;
  flex-grow: 1 !important;
  /* width: unset !important; */
}

.jybe-business-page .design-component-instance-node-2 {
  background-color: unset !important;
}

.jybe-business-page .design-component-instance-node-3 {
  font-weight: 700 !important;
}

.jybe-business-page .design-component-instance-node-4 {
  align-self: stretch !important;
  flex: 0 0 auto !important;
  width: 100% !important;
}

.jybe-business-page .frame-24 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 1.33vw;
  
  width: 100%;
}

.jybe-business-page .rectangle-wrapper {
  height: 16px;
  
  width: 16px;
}

.jybe-business-page .rectangle-20 {
  background-color: #ffffff;
  border: 0.05vw solid;
  border-color: #c8d5e0;
  border-radius: 5.56%;
  height: 16px;
  left: 0.11vw;
  
  top: 0.11vw;
  width: 16px;
}

.jybe-business-page .by-checking-ths-box {
  color: #ffffff;
  flex: 1;
  font-family: "Poppins", Helvetica;
  font-size: 1vw;
  font-weight: 700;
  
  line-height: normal;
  margin-top: -0.02vw;
  
}

.jybe-business-page .text-wrapper-33 {
  color: #00000099;
}

.jybe-business-page .text-wrapper-34 {
  color: #00000099;
  text-decoration: underline;
}

.jybe-business-page .button-wrapper {
  all: unset;
  align-items: flex-start;
  align-self: stretch;
  box-sizing: border-box;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 0.67vw;
  justify-content: center;
  padding: 0.67vw 0;
  
  width: 100%;
}

.jybe-business-page .button-3 {
  all: unset;
  align-items: center;
  background-color: #1a4ab1;
  border-radius: 12px;
  box-sizing: border-box;
  display: inline-flex;
  flex: 0 0 auto;
  justify-content: center;
  overflow: hidden;
  padding: 12px 24px; 
  
}

.jybe-business-page .text-7 {
  color: var(--defaultwhite);
  font-family: "Poppins", Helvetica;
  font-size: 1.4vw;
  font-weight: 700;
  
  line-height: normal;
  margin-top: -0.02vw;
  
  width: auto;
}

/* .index .text-7 {
  color: var(--defaultwhite);
  font-family: "Poppins", Helvetica;
  font-size: 0.89vw;
  font-weight: 700;
  
  line-height: normal;
  margin-top: -0.02vw;
  
  width: auto;
} */

.jybe-business-page .img-7 {
  height: 41.88vw;
  
  width: 36.46vw;
}

.jybe-business-page .overlap-group-7 {
  height: 41.46vw;
  left: 2.14vw;
  
  width: 32.19vw;
}

.jybe-business-page .group-21 {
  height: 41.46vw;
  left: 0;
  position: absolute;
  top: 0;
  width: 32.19vw;
}

.jybe-business-page .group-22 {
  height: 36.46vw;
  left: 0;
  position: absolute;
  top: 4vw;
  width: 11.09vw;
}

.jybe-business-page .rectangle-21 {
  height: 18.33vw;
  left: 0;
  position: absolute;
  top: 0;
  width: 11.09vw;
}

.jybe-business-page .rectangle-22 {
  height: 18.33vw;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 19.17vw;
  width: 11.09vw;
}

.jybe-business-page .group-23 {
  height: 36.46vw;
  left: 22.4vw;
  position: absolute;
  top: 4vw;
  width: 11.09vw;
}

.jybe-business-page .rectangle-23 {
  height: 18.33vw;
  left: 0;
  position: absolute;
  top: 19.17vw;
  width: 11.09vw;
}

.jybe-business-page .group-24 {
  height: 41.46vw;
  left: 11.09vw;
  position: absolute;
  top: 0;
  width: 11.09vw;
}

.jybe-business-page .rectangle-24 {
  height: 18.33vw;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 11.09vw;
}

.jybe-business-page .shutterstock {
  height: 18.33vw;
  left: 0;
  position: absolute;
  top: 25.22vw;
  width: 11.09vw;
}

.jybe-business-page .pexels-rebrand {
  height: 18.33vw;
  left: 22.4vw;
  position: absolute;
  top: 4vw;
  width: 11.09vw;
}

.jybe-business-page .pexels-rdne {
  height: 18.33vw;
  left: 11.09vw;
  position: absolute;
  top: 0;
  width: 11.09vw;
}

.jybe-business-page .pexels-shvetsa {
  height: 18.33vw;
  left: 11.09vw;
  position: absolute;
  top: 19.17vw;
  width: 11.09vw;
}

.jybe-business-page .section-4 {
  align-items: center;
  background-color: #ffffff;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 4.17vw;
  justify-content: center;
  padding: 5vh 5vw;
  
  width: 100vw;
  /* height: 100vh; */
}

.jybe-business-page .heading-wrapper {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 4.17vw;
  
  width: 100%;
  z-index: 1;
  justify-content: center;
}

.jybe-business-page .heading {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 0.89vw;
  
  width: 75vw;
}

.jybe-business-page .text-wrapper-35 {
  align-self: stretch;
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 8vw;
  font-weight: 700;
  
  line-height: normal;
  margin-top: -0.02vw;
  
  text-align: center;
}

.jybe-business-page .group-25 {
  height: 26.04vw;
  
  width: 24.18vw;
  z-index: 0;
}

.jybe-business-page .overlap-6 {
  height: 28.13vw;
  
  width: 28.33vw;
}

.jybe-business-page .group-26 {
  height: 19.38vw;
  left: 42.71vw;
  position: absolute;
  top: 3.89vw;
  width: 17.29vw;
}

.jybe-business-page .group-27 {
  height: 19.38vw;
  left: 8.54vw;
  position: absolute;
  top: 3.89vw;
  width: 16.51vw;
}

.jybe-business-page .group-28 {
  height: 26.04vw;
  left: 0;
  position: absolute;
  top: 0;
  width: 13.9vw;
}

.jybe-business-page .stripe-wrapper {
  align-items: center;
  background: linear-gradient(180deg, rgb(255, 255, 255) 0%, rgb(236.22, 239.48, 243.31) 100%);
  border: 0.05vw solid;
  border-color: var(--neutralsgray-100);
  border-radius: 1.11vw;
  box-shadow: 0px 0.21vw 0.21vw #0e24241a;
  display: flex;
  flex-direction: column;
  gap: 0.67vw;
  justify-content: center;
  left: 0;
  padding: 1.67vw;
  position: absolute;
  top: 0;
  width: 12.5vw;
}

.jybe-business-page .stripe-2 {
  height: 2.22vw !important;
  position: relative !important;
  width: 9.44vw !important;
}

.jybe-business-page .logo-3 {
  height: 7.78vw;
  left: 4.03vw;
  position: absolute;
  top: 13.33vw;
  width: 14.72vw;
}

.jybe-business-page .logo-4 {
  height: 7.78vw;
  left: -2.22vw;
  position: absolute;
  top: 22.22vw;
  width: 14.72vw;
}

.jybe-business-page .group-29 {
  height: 28.13vw;
  left: 49.74vw;
  position: absolute;
  top: 0;
  width: 21.15vw;
}

.jybe-business-page .group-30 {
  height: 17.36vw;
  left: 24.38vw;
  position: absolute;
  top: 4.17vw;
  width: 17.36vw;
}

.jybe-business-page .overlap-group-8 {
  height: 18.96vw;
  
}

.jybe-business-page .rectangle-25 {
  border: 0.05vw solid;
  border-color: #3e60df;
  border-radius: 3.33vw;
  height: 13.89vw;
  left: 1.81vw;
  position: absolute;
  top: 1.81vw;
  width: 13.89vw;
}

.jybe-business-page .rectangle-26 {
  border: 0.05vw solid;
  border-color: #ff4073;
  border-radius: 3.33vw;
  height: 15.42vw;
  left: 1.11vw;
  opacity: 0.5;
  position: absolute;
  top: 1.11vw;
  width: 15.42vw;
}

.jybe-business-page .rectangle-27 {
  border: 0.05vw solid;
  border-color: #ffb44e;
  border-radius: 3.33vw;
  height: 16.67vw;
  left: 0.74vw;
  opacity: 0.5;
  position: absolute;
  top: 0.74vw;
  width: 16.67vw;
}

.jybe-business-page .rectangle-28 {
  border: 0.05vw solid;
  border-color: #ffb44e;
  border-radius: 3.33vw;
  height: 17.57vw;
  left: 0;
  opacity: 0.25;
  position: absolute;
  top: 0;
  width: 17.57vw;
}

.jybe-business-page .logo-5 {
  height: 17.78vw;
  left: 0.59vw;
  position: absolute;
  top: 1.48vw;
  width: 16.67vw;
}

.jybe-business-page .frame-25 {
  align-items: center;
  background: linear-gradient(
    180deg,
    rgba(223.29, 223.12, 255, 0.15) 0%,
    rgba(255, 209.31, 221.34, 0.15) 50%,
    rgba(255, 244.95, 209.31, 0.15) 100%
  );
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 4.17vw;
  padding: 5.21vh 12.5vw;
  
  width: 100%;
}

.jybe-business-page .headings {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 0.89vw;
  
}

.jybe-business-page .text-wrapper-36 {
  color: #00000099;
  font-family: "Poppins", Helvetica;
  font-weight: 700;
  font-size: 6vw;
  /* 
  line-height: 1.42vw;
  width: 55vw; */
  
  text-align: center;
}

.jybe-business-page .frame-26 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 1.78vw;
  
  width: 100%;
}

.jybe-business-page .container {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 1.78vw;
  
  width: 100%;
}

.jybe-business-page .card {
  align-items: flex-start;
  align-self: stretch;
  background-color: #ffffff80;
  border: 0.05vw solid;
  border-color: #ffffff;
  border-radius: 3.33vw;
  box-shadow: 0px 0.67vw 0.67vw #0e24241a;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 2.22vw;
  padding: 2.78vw;
  
}

.jybe-business-page .section-heading {
  align-self: stretch;
  color: #0e2424;
  font-family: "Inter", Helvetica;
  font-size: 2vw;
  font-weight: 700;
  
  line-height: normal;
  
}

.jybe-business-page .section-heading-2 {
  align-self: stretch;
  color: #00000099;
  font-family: "Poppins", Helvetica;
  font-size: 1vw;
  font-weight: 700;
  
  /* line-height: 1.06vw; */
  
}

.jybe-business-page .div-3 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 4.17vw;
  
  width: 100%;
  z-index: 2;
  justify-content: center;
}

.jybe-business-page .text-wrapper-37 {
  color: #00000099;
  font-family: "Poppins", Helvetica;
  font-size: 0.89vw;
  font-weight: 700;
  
  line-height: 1.42vw;
  
  text-align: center;
  width: 55vw;
  z-index: 1;
}

.jybe-business-page .frame-27 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 4.17vw;
  justify-content: center;
  
  width: 100vw;
  z-index: 0;
}

.jybe-business-page .frame-28 {
  align-items: flex-end;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 9.72vw;
  justify-content: center;
  
  width: 100%;
}

.jybe-business-page .salesforce-com-logo {
  height: 2.22vw;
  
  width: 3.15vw;
}

.jybe-business-page .magento {
  height: 2.22vw;
  
  width: 5.93vw;
}

.jybe-business-page .bc-logo-dark {
  height: 2.22vw;
  
  width: 6.67vw;
}

.jybe-business-page .frame-29 {
  align-items: flex-end;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  
  width: 100%;
}

.jybe-business-page .shopify-logo {
  height: 2.22vw;
  
  width: 5.33vw;
}

.jybe-business-page .adyen-corporate-logo {
  height: 2.22vw;
  
  width: 5.13vw;
}

/* .jybe-business-page .footer {
  align-items: flex-start;
  background-color: var(--neutralsgray-100);
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  padding: 0.67vw 12.5vw;
  
  width: 100%;
} */

.jybe-business-page .group-31 {
  height: 22.4vw;
  left: 23.85vw;
  opacity: 0.5;
  position: absolute;
  top: -12.29vw;
  width: 52.5vw;
}

.jybe-business-page .overlap-7 {
  height: 22.4vw;
  
}
.jybe-business-page .pyramid-5 {
  height: 33.33vw;
  left: 5.35vw;
  opacity: 0.5;
  position: absolute;
  top: 5.35vw;
  transform: rotate(-110deg);
  width: 33.33vw;
}

.jybe-business-page .overlap-group-9 {
  height: 58.33vw;
  left: -12.29vw;
  
  top: -12.29vw;
  width: 50vw;
}

.jybe-business-page .pyramid-6 {
  height: 37.5vw;
  left: 4.17vw;
  object-fit: cover;
  position: absolute;
  top: 8.33vw;
  transform: rotate(110deg);
  width: 45.83vw;
}

.jybe-business-page .mask-group-3 {
  height: 37.5vw;
  left: 4.17vw;
  position: absolute;
  top: 8.33vw;
  transform: rotate(110deg);
  width: 45.83vw;
}

.jybe-business-page .pyramid-7 {
  height: 33.33vw;
  left: 17.71vw;
  opacity: 0.5;
  position: absolute;
  top: 3.47vw;
  transform: rotate(-95deg);
  width: 33.33vw;
}

.jybe-business-page .overlap-8 {
  height: 41.67vw;
  left: -4vw;
  
  top: -4vw;
  width: 47.92vw;
}

.jybe-business-page .pyramid-8 {
  height: 32.29vw;
  left: -1.04vw;
  object-fit: cover;
  position: absolute;
  top: 3.47vw;
  transform: rotate(95deg);
  width: 40.62vw;
}

.jybe-business-page .mask-group-4 {
  height: 32.29vw;
  left: -1.04vw;
  position: absolute;
  top: 3.47vw;
  transform: rotate(95deg);
  width: 40.62vw;
}

.jybe-business-page .pyramid-9 {
  height: 33.33vw;
  left: 28.65vw;
  opacity: 0.5;
  position: absolute;
  top: 1.82vw;
  transform: rotate(-85deg);
  width: 33.33vw;
}

.jybe-business-page .overlap-9 {
  height: 39.58vw;
  left: -4vw;
  
  top: -2.08vw;
  width: 34.38vw;
}

.jybe-business-page .pyramid-10 {
  height: 30.47vw;
  left: -1.56vw;
  object-fit: cover;
  position: absolute;
  top: 3.47vw;
  transform: rotate(85deg);
  width: 40.62vw;
}

.jybe-business-page .mask-group-5 {
  height: 30.47vw;
  left: -1.56vw;
  position: absolute;
  top: 3.47vw;
  transform: rotate(85deg);
  width: 40.62vw;
}

.jybe-business-page .div-relative {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: 1440px;
  padding: 2.22vw 0px;
  
}

.jybe-business-page .div-relative-2 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 1.67vw;
  padding: 4.44vw 16.67vw;
  
  width: 100%;
}

.jybe-business-page .heading-2 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 1.11vw;
  
  width: 100%;
}

.jybe-business-page .text-wrapper-38 {
  align-self: stretch;
  color: #00000099;
  font-family: "Poppins", Helvetica;
  font-size: 1.67vw;
  font-weight: 700;
  
  line-height: normal;
  
  text-align: center;
}

.jybe-business-page .div-relative-3 {
  align-items: flex-start;
  align-self: stretch;
  background-color: #f1f5f8;
  border-radius: 1.11vw;
  display: flex;
  flex-direction: column;
  gap: 4.44vw;
  padding: 3.54vw 3.47vw 4.31vw;
  
  width: 100%;
}

.jybe-business-page .div-grid {
  align-items: center;
  display: flex;
  gap: 10vw;
  justify-content: center;
  padding: 0px 1.06e-5px 0px 0px;
  
  width: 88.89vw;
}

.jybe-business-page .div-wforest-full {
  align-items: flex-start;
  display: inline-flex;
  padding: 4.44vw 0px 3.29vw;
  
}

.jybe-business-page .nav {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 0.91vw;
  padding: 0px 0px 0.01px;
  
}

.jybe-business-page .link-margin {
  align-items: flex-start;
  display: inline-flex;
  padding: 0px 2.78vw 1.35vw 0px;
  
}

.jybe-business-page .text-wrapper-39 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 1.11vw;
  font-weight: 700;
  
  line-height: 1.85vw;
  margin-top: -0.07vw;
  
  white-space: nowrap;
  width: fit-content;
}

.jybe-business-page .link-start-with-wrapper {
  align-items: flex-start;
  display: inline-flex;
  margin-top: -2.78vw;
  padding: 0px 3.89vw 1.35vw 0px;
  
}

.jybe-business-page .link-sign-in-wrapper {
  align-items: flex-start;
  display: inline-flex;
  margin-top: -2.78vw;
  padding: 0px 7.44vw 1.35vw 0px;
  
}

.jybe-business-page .link-contact-wrapper {
  align-items: flex-start;
  display: inline-flex;
  margin-top: -2.78vw;
  padding: 0px 6.89vw 1.35vw 0px;
  
}

.jybe-business-page .link-pricing-wrapper {
  align-items: flex-start;
  display: inline-flex;
  padding: 0px 2.17vw 1.35vw 0px;
  
}

.jybe-business-page .link-blog-wrapper {
  align-items: flex-start;
  display: inline-flex;
  margin-top: -2.78vw;
  padding: 0px 3.24vw 1.35vw 0px;
  
}

.jybe-business-page .link-glossary-wrapper {
  align-items: flex-start;
  display: inline-flex;
  margin-top: -2.78vw;
  padding: 0px 0.9vw 1.35vw 0px;
  
}

.jybe-business-page .link-careers-wrapper {
  align-items: flex-start;
  display: inline-flex;
  margin-top: -2.78vw;
  padding: 0px 1.48vw 1.35vw 0px;
  
}

.jybe-business-page .nav-wrapper {
  align-items: flex-start;
  display: inline-flex;
  padding: 4.44vw 0px 6.59vw;
  
}

.text{
  font-family: "Poppins", Helvetica;
}

/* .jybe-business-page .link-wrapper {
  align-items: center;
  display: inline-flex;
  
}

.jybe-business-page .link {
  align-items: center;
  display: inline-flex;
  gap: 1.11vw;
  padding: 0px 1.06vw 0.33vw 0px;
  
} */

.jybe-business-page .SVG {
  height: 1.67vw;
  
  width: 1.67vw;
}

.jybe-business-page .link-margin-2 {
  align-items: center;
  display: inline-flex;
  margin-top: -2.78vw;
  padding: 0px 0px 1.11vw;
  
}

.jybe-business-page .link-2 {
  align-items: center;
  display: inline-flex;
  gap: 1.11vw;
  padding: 0px 3.13vw 0.33vw 0px;
  
}

.jybe-business-page .link-3 {
  align-items: center;
  display: inline-flex;
  gap: 1.11vw;
  padding: 0px 0.33vw 0.33vw 0px;
  
}

.jybe-business-page .div-wforest-full-2 {
  align-items: flex-start;
  display: inline-flex;
  padding: 4.44vw 0px 10vw;
  
}

.jybe-business-page .nav-2 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 0.97vw;
  padding: 0px 0px 1.11vw;
  
}

.jybe-business-page .terms-conditions-wrapper {
  align-items: flex-start;
  display: inline-flex;
  padding: 0px 0.21vw 0.33vw 0px;
  
}

.jybe-business-page .link-4 {
  align-items: flex-start;
  display: inline-flex;
  padding: 0px 3.13vw 0.33vw 0px;
  
}

.jybe-business-page .div-mt {
  align-items: center;
  display: flex;
  justify-content: space-between;
  
  width: 100%;
}

.jybe-business-page .logo-6 {
  height: 2.78vw;
  
  width: 11.67vw;
}

.jybe-business-page .p-t {
  align-items: flex-start;
  display: inline-flex;
  justify-content: flex-end;
  padding: 0px 0.01vw 0.07vw 0.14vw;
  
}

.jybe-business-page .text-wrapper-40 {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 0.83vw;
  font-weight: 700;
  
  line-height: 1.38vw;
  margin-top: -0.07vw;
  
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.jybe-business-page .navbar {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 0.56vw;
  justify-content: center;
  left: 0;
  padding: 0 5% 16px;
  position: absolute;
  top: 0;
  width: 100vw;
  z-index: 4;
}

.jybe-business-page .frame-30 {
  align-items: center;
  align-self: stretch;
  background-color: #ffffff;
  border-radius: 0px 0px 1.11vw 1.11vw;
  box-shadow: 0px 0.56vw 0.56vw #0000001a;
  display: flex;
  justify-content: space-between;
  padding: 0.84vw;
  
  width: 100%;
}

.jybe-business-page .logo-7 {
  align-self: stretch;
  flex: 0 0 auto;
  
}

.jybe-business-page .frame-31 {
  align-items: center;
  display: inline-flex;
  gap: 0.56vw;
  
}

.jybe-business-page .design-component-instance-node-5 {
  flex: 0 0 auto !important;
}

.jybe-business-page .design-component-instance-node-6 {
  font-family: "Poppins", Helvetica !important;
  font-size: 0.83vw !important;
  font-style: unset !important;
  font-weight: 700 !important;
  line-height: normal !important;
}

.jybe-business-page .pexels-ketut {
  height: 25vw;
  left: 75.52vw;
  position: absolute;
  top: 93.52vw;
  width: 9.67vw;
}

  
.navbar .frame {
  align-items: center;
  align-self: stretch;
  background-color: #ffffff;
  border-radius: 0px 0px 16px 16px;
  box-shadow: 0px 10px 10px #0000001a;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-around;
  padding: 16px 0px;
  
  width: 100%;
}

.navbar .logo {
  align-self: stretch;
  flex: 0 0 auto;
  
}

.navbar .frame-2 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  
}

.navbar .design-component-instance-node {
  flex: 0 0 auto !important;
}

.navbar .inactive-text-tab-wrapper {
  align-items: center;
  border-radius: 8px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  padding: 16px 20px;
  
  color: rgba(96, 105, 112, 1);;
  cursor: pointer;
  font-family: "Poppins", Helvetica;
}


.sixppl{
  z-index:10;
  /* height:90vh; */
  width: 50vw;
}

@media screen and (min-width: 600px) {
  .logo-2 {
      width: 15vw;
  }
}