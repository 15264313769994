@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  --Default-white: #ffffff;
  --Color-5: #1e265e;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.bars {
  display: none;
}
body {
  background-color: #f9f9fb;
}
.container {
  width: 100%;
  background-color: #f9f9fb;
  margin-left: auto;
  margin-right: auto;
}

header {
  width: 100%;
  height: 83px;
  flex-shrink: 0;
  border-radius: 0px 0px 16px 16px;
  background: #fff;
  box-shadow: 0px 16px 16px 0px rgba(0, 0, 0, 0.1);
  padding: 0 92px 0 49px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
}

.logo {
  width: 138px;
  height: 32.872px;
  flex-shrink: 0;
}

.ma-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.header-right-side {
  display: flex;
  align-items: center;
  gap: 30px;
}

.header-right-side a {
  display: flex;
  width: 184px;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background: #1a4ab1;
  color: var(--Default-white, #fff);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;
}

.header-right-side div {
  display: flex;
  align-items: center;
  gap: 21px;
}

.bell-img svg {
  width: 29px;
  height: 30px;
  stroke-width: 2px;
  stroke: rgba(30, 38, 94, 0.4);
}

.header-right-side div p {
  color: #1e265e;
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  opacity: 0.4;
}

.content-wrapper {
  display: flex;
  gap: 35px;
}

.pages-wrapper {
  width: 311px;
  height: 100vh;
  flex-shrink: 0;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 150px;
  padding-bottom: 40px;
}

.pages-wrapper > div {
  display: flex;
  flex-direction: column;
}

.page-link {
  width: 311px;
  height: 74px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
}

.page-link a img {
  width: 27.6px;
  height: 27.6px;
  flex-shrink: 0;
}

.page-link a {
  width: 100%;
  height: 46px;
  color: var(--Color-5, #1e265e);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 0.9;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 17px;
  padding-left: 46px;
}

.border {
  width: 7px;
  height: 46px;
  flex-shrink: 0;
  border-radius: 0px 9.2px 9.2px 0px;
  background: var(
    --Gradient-3,
    linear-gradient(180deg, #b09fff 0%, #8d79f6 100%)
  );
  box-shadow: 0px 0px 5.75px 0px rgba(139, 117, 245, 0.4);
  display: none;
}

.active a {
  color: #000;
  padding-left: 39px;
}

.active {
  background: rgba(42, 41, 234, 0.05);
}

.active .border {
  display: block;
}

.yousaved {
  display: inline-flex;
  padding: 20px 16px;
  flex-direction: column;
  align-items: flex-end;
  gap: 23px;
  border-radius: 10px;
  background: #f5f5f5;
  margin: 0 32px;
  gap: 23px;
}

.yousaved div {
  display: flex;
  align-items: flex-end;
  gap: 34px;
}

.yousaved div img {
  width: 36px;
  height: 38px;
  object-fit: cover;
}

.yousaved div p {
  width: 144px;
  color: #000;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
}

.yousaved div p span {
  font-weight: 700;
}

.content {
  display: flex;
  flex: 1;
  height: 100vh;
  align-items: flex-start;
  gap: 29px;
  padding-top: 120px;
  padding-right: 30px;
  overflow: auto;
}

.subscription-wrapper {
  width: 467px;
  border-radius: 16px;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 17px;
  padding: 24px 24px;
  padding-bottom: 70px;
  background-color: #fff;
}

.subscription-wrapper h1 {
  color: #000;
  font-family: Poppins;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.subscription {
  display: flex;
  padding: 15px;
  flex-direction: column;
  align-items: flex-start;
  gap: 13px;
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.15);
}

.company-info {
  display: flex;
  width: 100%;
  padding: 10px;
  align-items: center;
  border-radius: 8px;
}

.company-logo {
  width: 54px;
  height: 54px;
  flex-shrink: 0;
  border-radius: 80px;
}

.company-l1 {
 background: 
   /* url(./images/netflix-logo-transparent-free-png.svg), */
    lightgray 50% / cover no-repeat;
}

.company-l2 {
  background: url(./images/netflix-logo2-transparent-free-png.svg),
    lightgray 50% / cover no-repeat;
}

.company-name {
  display: flex;
  padding: 10px 20px;
  align-items: center;
  flex: 1 0 0;
}

.company-name h2 {
  flex: 1 0 0;
  color: #000;
  font-family: Poppins;
  font-size: 20.339px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.plan-saving {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 10px;
  background: rgba(244, 244, 244, 0.5);
}

.plan-saving > div {
  display: flex;
  padding: 0px 20px;
  align-items: center;
  gap: 32px;
  align-self: stretch;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.plan-saving > div:last-child {
  border-bottom: none;
}

.plan-saving > div > p {
  width: 144px;
  color: #000;
  font-family: Poppins;
  font-size: 17.34px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.plan-saving > div > div {
  display: flex;
  padding: 10px 10px;
  align-items: center;
  flex: 1 0 0;
}

.plan-saving > div > div > p {
  flex: 1 0 0;
  color: #000;
  font-family: Poppins;
  font-size: 20.339px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  gap: 13px;
  align-items: center;
}

.plan-saving > div > div > p > span {
  color: #1e265e;
  font-family: Poppins;
  font-size: 14.237px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  opacity: 0.4;
}

.new-sub {
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 12px;
  background: #1a4ab1;
  color: var(--Default-white, #fff);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: transparent;
  cursor: pointer;
}

.right {
  width: 100%;
}

.right-top {
  width: 100%;
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
}

.right-top p {
  color: #000;
  font-family: Poppins;
  font-size: 30px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.right-top p span {
  color: #9d9df6;
  font-weight: 500;
}

.right-top > div {
  height: 116px;
  flex-shrink: 0;
  display: flex;
  gap: 20px;
}

.right-top > div > div {
  width: 245px;
  height: 116px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 23px;
}

.ma-img {
  width: 34px;
  height: 34px;
  flex-shrink: 0;
  /* background: url(./images/Subtract.png), lightgray 50% / cover no-repeat; */
}

.rt-content {
  width: 75px;
  height: 50px;
  flex-shrink: 0;
}

.rt-content p {
  color: #000;
  font-family: Nunito;
  font-size: 22px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  opacity: 0.7;
}

.rt-content p:last-child {
  color: var(--Text, #030229);
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 0.7;
}

.monthly-plan {
  flex-shrink: 0;
  background: #fff;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  padding: 33px 30px;
}

.plan-wrapper {
  width: 100%;
  height: 202px;
  flex-shrink: 0;
  border: 0.903px solid rgba(197, 197, 197, 0.5);
  border-radius: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.plan-wrapper > div {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 14px;
}

.plan-wrapper > div > h5 {
  color: #000;
  font-family: Poppins;
  font-size: 18.054px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.plan-wrapper > div > .line {
  width: 206px;
  height: 1px;
  background: rgba(0, 0, 0, 0.1);
}

.plan-content {
  width: 195.898px;
  height: 118.19px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.plan {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.plan > div {
  display: flex;
  gap: 19px;
}

.plan > div > p {
  color: #000;
  font-family: Poppins;
  font-size: 18.36px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.plan > div > p > span {
  color: #1e265e;
  font-size: 12.852px;
  font-weight: 300;
}

.tick-img {
  width: 18.054px;
  height: 18.054px;
  flex-shrink: 0;
  background-repeat: no-repeat;
  background-size: cover;
}

.img8 {
  background: url(./images/image-8.svg);
}

.img9 {
  background: url(./images/image-9.svg);
}

@media screen and (max-width: 1800px) {
  .subscription-wrapper {
    width: 367px;
  }
  .subscription-wrapper {
    display: block;
  }
  .subscription-wrapper h1 {
    margin-bottom: 1rem;
    text-align: center;
  }
  .plan-saving > div > div > p {
    font-size: 15px;
  }
  .plan-saving > div {
    justify-content: space-between;
  }
  .subscription {
    margin-bottom: 17px;
  }
  .plan-saving > div > p {
    font-size: 15px;
    width: unset;
  }
  .plan-saving > div > div {
    flex: unset;
    padding-left: 0px;
    padding-right: 0px;
  }
  .new-sub {
    width: 100%;
  }
}
@media screen and (max-width: 1700px) {
  .monthly-plan {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media screen and (max-width: 1500px) {
  .right-top p {
    font-size: 20px;
  }
  .rt-content p {
    font-size: 18px;
  }
  .right-top > div > div {
    height: unset;
    width: unset;
    padding: 1rem;
  }
  .right-top {
    align-items: center;
  }
  .monthly-plan {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (max-width: 1300px) {
  .content {
    display: flex;
    flex-direction: column-reverse;
  }

  .content > * {
    width: 100%;
  }
  .subscription-wrapper {
    width: 100%;
  }
}
@media screen and (max-width: 1044px) {
  .bars {
    display: block;
    width: 30px;
  }
  .right-top {
    flex-direction: column;
    gap: 1rem;
  }
  .right-top > div {
    width: 100%;
  }
  .right-top > div > * {
    flex: 1;
  }
  header {
    padding-left: 20px;
    padding-right: 20px;
  }
  .pages-wrapper {
    position: fixed;
    left: -100%;
    top: 0px;
    z-index: 99;
    padding-top: 20px;
    background-color: #fff;
    width: 250px;
    transition: 0.3s ease all;
  }
  .content {
    height: unset;
  }
  .pages-wrapper.active {
    left: 0%;
  }
  .right-top {
    padding-left: 0px;
  }
  .page-link {
    width: 100%;
  }
  .yousaved {
    display: none !important;
  }
  .content {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media screen and (max-width: 560px) {
  .right-top > div > div {
    justify-content: unset;
  }
  .monthly-plan {
    grid-template-columns: 1fr;
    padding: 1rem;
  }
  .header-right-side a {
    display: none;
  }
}
@media screen and (max-width: 444px) {
  .right-top > div {
    flex-direction: column;
    height: unset;
  }
  .header-right-side div {
    gap: 10px;
  }
  .header-right-side {
    gap: 10px;
  }
  .logo {
    width: 100px;
    height: unset;
  }
  .plan-content,
  .plan-wrapper {
    height: unset;
  }
  .plan-content,
  .plan-wrapper > div {
    width: 90%;
  }
  .plan-wrapper > div > .line {
    width: 90%;
  }
  .plan-wrapper {
    padding-bottom: 1rem;
    padding-top: 1rem;
  }
  .bell-img svg {
    width: 17px;
  }
  .header-right-side div p {
    font-size: 14px;
  }
}
