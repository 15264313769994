.footer {
    align-items: flex-start;
    background-color: #E2E8ED;
    display: flex;
    flex: 0 0 auto;
    justify-content: center;
    mix-blend-mode: normal;
    position: relative;
    width: 100vw;
  }
  
  .footer .group-5 {
    position: absolute;
    width: 100%;
    height: 366px;
    overflow: hidden;
  }
  
  .footer .div-relative {
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    max-width: 100vw;
    padding: 32px 0px 0px 0px;
    position: relative;
  }
  
  .footer .div-relative-2 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 24px;
    /* padding: 4vw 240px; */
    padding: 0px;
    position: relative;
    width: 100%;
    margin-bottom: 24px;
  }
  
  .footer .text-wrapper-22 {
    align-self: stretch;
    color: var(--neutralsblack);
    font-family: "Poppins", Helvetica;
    font-size: 4vw;
    font-weight: 600;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    text-align: center;
  }
  
  .footer .div-relative-3 {
    align-items: flex-start;
    align-self: stretch;
    background-color: var(--neutralsgray-000);
    border-radius: 16px;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    padding: 2.5vw!important;
    position: relative;
    width: 90%;
    gap: 2.5vh;
  }
  
  .footer .div-grid {
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    justify-content: center;
    position: relative;
    width: 100%;
    flex-wrap: wrap;
  }
  
  .footer .nav-grid {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  
  .footer .nav {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    position: relative;
  }
  
  /* .footer .text-wrapper-23 {
    color: #000000;
    font-family: "Poppins", Helvetica;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 24px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  } */
  
  .footer .link-start-with-wrapper {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    margin-top: -4.2px;
    padding: 0px 4.7px 20.23px 0px;
    position: relative;
  }
  
  .footer .link-sign-in-wrapper {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    margin-top: -4.2px;
    padding: 0px 88.7px 20.23px 0px;
    position: relative;
  }
  
  .footer .link-contact-wrapper {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    margin-top: -4.2px;
    padding: 0px 82.7px 20.23px 0px;
    position: relative;
  }
  
  .footer .link-pricing-wrapper {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    padding: 0px 26.08px 20.23px 0px;
    position: relative;
  }
  
  .footer .link-blog-wrapper {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    margin-top: -4.2px;
    padding: 0px 38.81px 20.23px 0px;
    position: relative;
  }
  
  .footer .link-glossary-wrapper {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    margin-top: -4.2px;
    padding: 0px 10.81px 20.23px 0px;
    position: relative;
  }
  
  .footer .link-careers-wrapper {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    margin-top: -4.2px;
    padding: 0px 17.81px 20.23px 0px;
    position: relative;
  }
  
  .footer .nav-wrapper {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    padding: 53.77px 0px 79.94px;
    position: relative;
  }
  
  .footer .link-wrapper {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    position: relative;
  }
  
  .footer .link {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 20px;
    /* padding: 0px 13.67px 4.23px 0px; */
    position: relative;
    cursor: pointer;
  }
  
  .footer .SVG {
    height: 20px;
    position: relative;
    width: 20px;
  }
  
  .footer .link-margin-2 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    position: relative;
  }
  
  .footer .link-2 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 20px;
    padding: 0px 25.67px 4.23px 0px;
    position: relative;
  }
  
  .footer .link-3 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 20px;
    padding: 0px 2.67px 4.23px 0px;
    position: relative;
  }
  
  .footer .div-wforest-full-2 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    padding: 53.77px 0px 120px;
    position: relative;
  }
  
  .footer .nav-2 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 11.77px;
    padding: 0px 0px 16px;
    position: relative;
  }
  
  .footer .terms-conditions-wrapper {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    padding: 0px 3.02px 4.23px 0px;
    position: relative;
    width: 10px;
    cursor: pointer;
  }
  
  .footer .link-4 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    position: relative;
    cursor: pointer;
  }
  
  .footer .logo-10 {
    height: 40px;
    position: relative;
    width: 167.92px;
  }
  
  .footer .p-t {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    justify-content: flex-end;
    padding: 0px 0.14px 0.91px 2px;
    position: relative;
  }
  
  .footer .text-wrapper-24 {
    color: #000000;
    font-family: "Poppins", Helvetica;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 19.9px;
    margin-top: -1px;
    position: relative;
    text-align: right;
    white-space: nowrap;
    width: fit-content;
  }

.footer .div-7 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;
    position: relative;
    width: 100%;
    height: 10vh;
  }
  .footer .button {
    display: flex;
    flex-wrap: nowrap;
    background-color: #1a4ab1!important;
    color: white;
    border: 0px;
    font-size: 2vh;
    border-radius: 12px;
  }

  .footer .text-wrapper-3{
    text-align: center;
    font-family: "Poppins", Helvetica;
    font-size: 1.5vw;
  }

  .div-3-holder{
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 3.5vh;
  }

  .div-3-holder-holder{
    width: 100%;
  }

  @media screen and (min-width: 451px) {
    .div-relative-2{
      padding: 4vw 240px;
    }
    .div-grid{
      gap: 12vw;
    }
    .link-margin{
      width: auto;
    }
    .terms-conditions-wrapper{
      width: auto;
    }
    .link-4{
      padding: 0px 48.02px 4.23px 0px;
    }
    .link-margin{
      width: auto!important;
    }
    /* .div-relative-3{
      width: 100%!important;
    } */
  }

  .pointer,
  .link,
  .text-wrapper-23{
    font-family: "Poppins", Helvetica;
    font-size: 1.5vw;
  }

  .group-5{
    top: 0vh;
    left: 0vw;
  }