.inputs-text {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
  width: 343px;
}

.inputs-text .frame-2 {
  align-self: stretch;
  border: 1px solid;
  border-radius: 8px;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  padding: 12px;
  position: relative;
  width: 100%;
}

.inputs-text .placeholder {
  flex: 1;
  font-family: "Poppins", Helvetica;
  font-size: 1.5vw;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 26px;
  margin-top: -1px;
  position: relative;
  width: 50%;
}

.inputs-text .span {
  color: #000000;
}

.inputs-text .text-wrapper-2 {
  color: #9ba6b0;
}

.inputs-text .password-2 {
  flex: 1;
  font-family: "Poppins", Helvetica;
  font-size: 1.5vw;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 26px;
  margin-top: -1px;
  position: relative;
}

.inputs-text .eye-no {
  height: 16px;
  position: relative;
  width: 16px;
}

.inputs-text .icon {
  height: 14px;
  left: 1px;
  position: absolute;
  top: 1px;
  width: 15px;
}

.inputs-text .div-wrapper {
  align-items: flex-start;
  align-self: stretch;
  background-color: #ffffff;
  border: 1px solid;
  border-color: var(--alertsdanger);
  border-radius: 8px;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  padding: 12px;
  position: relative;
  width: 100%;
}

.inputs-text .text-wrapper-3 {
  color: var(--neutralsblack);
  flex: 1;
  font-family: "Poppins", Helvetica;
  font-size: 1.5vw;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 26px;
  margin-top: -1px;
  position: relative;
}

.inputs-text .text-wrapper-4 {
  align-self: stretch;
  color: var(--alertsdanger);
  font-family: "Poppins", Helvetica;
  font-size: 1.5vw;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 26px;
  position: relative;
}

.inputs-text .state-focus {
  background-color: #ffffff;
  border-color: #333399;
}

.inputs-text .password {
  align-items: center;
}

.inputs-text .text {
  align-items: flex-start;
}

.inputs-text .state-default {
  background-color: #ffffff;
  border-color: var(--neutralsgray-200);
}

.inputs-text .state-typing {
  background-color: #ffffff;
  border-color: #333399;
}

.inputs-text .state-disable {
  background-color: var(--neutralsgray-000);
  border-color: var(--neutralsgray-200);
}

.inputs-text .state-error {
  background-color: #ffffff;
  border-color: var(--neutralsgray-200);
}

.inputs-text .frame-2.state-focus .placeholder {
  color: transparent;
}

.inputs-text .frame-2.state-default .placeholder {
  color: var(--neutralsgray-300);
}

.inputs-text .frame-2.state-typing .placeholder {
  color: var(--neutralsblack);
}

.inputs-text .frame-2.state-disable .placeholder {
  color: var(--neutralsgray-400);
}

.inputs-text .frame-2.state-error .placeholder {
  color: var(--neutralsgray-300);
}

.inputs-text .frame-2.state-focus .password-2 {
  color: transparent;
}

.inputs-text .frame-2.state-default .password-2 {
  color: var(--neutralsgray-300);
}

.inputs-text .frame-2.state-typing .password-2 {
  color: var(--neutralsblack);
}

 input {
  outline: none;
  border: 0px;
}

@media screen and (max-width: 451px) {
  .text-wrapper-4,
  .text-wrapper-3,
  .password-2,
  .placeholder{
    font-size: 16px!important;
  }
}